<template>
  <div class="apply-detail">
    <van-nav-bar left-arrow title="变更作业票" @click-left="$router.back()">
      <template #right>
        <span @click="onShowProgress">工作进度</span>
      </template>
    </van-nav-bar>
    <div class="base-info">
      <div class="ban">{{ form.changeTypeName }}</div>
      <div v-if="form.flowState" class="flow-state">{{ form.flowState }}</div>
      <h3>{{ form.name }}</h3>
      <p>
        变更单号：<span>{{ form.code }}</span>
      </p>
      <p>
        申请部门：<span>{{ form.departmentName }}</span>
      </p>
      <p>
        申请人：<span>{{ form.proposerName }}</span>
      </p>
      <p>
        提交人：<span>{{ form.creatorName }}</span>
      </p>
    </div>
    <h2 class="main-title">工作票信息</h2>
    <render-form
      :render-list="renderList"
      :render-form-data="renderFormData"
      :facility-data="facilities"
      :jsa-data="jsas"
      :indicator-data="indicators"
      :position-data="positions"
      :user-data="users"
      :dept-data="department"
      :feedstock-data="feedstocks"
      :intermediate-data="intermediates"
      :material-data="materials"
      :product-data="products"
    ></render-form>
    <!--  底部操作按钮  -->
    <template v-if="showTransfer || (showWorkflowButton && form.status)">
      <div class="empty-box"></div>
      <div class="button-operate">
        <div class="btn-group">
          <van-button
            v-if="showTransfer"
            round
            type="info"
            @click="redeployVisible = true"
            >转派</van-button
          >
          <van-button
            v-if="showWorkflowButton && form.status"
            round
            type="info"
            @click="showWorkflowForm"
            >{{ workflowButtonText }}</van-button
          >
        </div>
      </div>
    </template>
    <!--  核准  -->
    <van-popup
      v-model="approveVisible"
      position="bottom"
      :style="{ height: '80vh' }"
    >
      <div class="popup-container redeploy-popup-container">
        <div class="popup-header">
          <h3 class="popup-title">{{ form.flowState }}</h3>
          <i
            class="iconclose iconfont iconfont-danchuangguanbi"
            @click="approveVisible = false"
          ></i>
        </div>
        <div class="popup-body">
          <render-form
            v-if="approveLoaded"
            ref="approveRenderForm"
            :editable="true"
            :facility-data="facilities"
            :jsa-data="jsas"
            :indicator-data="indicators"
            :position-data="positions"
            :user-data="users"
            :dept-data="department"
            :feedstock-data="feedstocks"
            :intermediate-data="intermediates"
            :material-data="materials"
            :product-data="products"
            :render-list="approveRenderList"
            :render-form-data="approveRenderFormData"
          ></render-form>
        </div>
        <div class="popup-footer">
          <div class="btn-group">
            <van-button round @click="onApproveClose">取消</van-button>
            <van-button
              round
              :loading="approveLoading"
              :disabled="approveDisabled"
              type="info"
              @click="onApproveSubmit"
              >确定</van-button
            >
          </div>
        </div>
      </div>
    </van-popup>
    <!--  转派  -->
    <van-popup
      v-model="redeployVisible"
      position="bottom"
      :style="{ height: '280px' }"
    >
      <div class="popup-container redeploy-popup-container">
        <div class="popup-header">
          <h3 class="popup-title">转派</h3>
          <i
            class="iconclose iconfont iconfont-danchuangguanbi"
            @click="redeployVisible = false"
          ></i>
        </div>
        <div class="popup-body">
          <p class="tip">
            <i class="iconfont iconfont-tishi"></i
            >&nbsp;&nbsp;确认将核准权限转派?转派后您将不再拥有该权限
          </p>
          <van-field
            readonly
            :value="redeployUser"
            required
            label="转派对象"
            placeholder="请选择转派对象"
            @click="selectPersonVisible = true"
          >
          </van-field>
          <!--  人员选择  -->
          <select-person
            title="选择转派人员"
            :visible="selectPersonVisible"
            :show-department-name="false"
            :show-phone-number="true"
            :multiple="false"
            department-disabled-key="--"
            position="bottom"
            :choose-value="chooseValue"
            :exclude-person-ids="excludePersonIds"
            @close="selectPersonVisible = false"
            @confirm="onPersonConfirm"
          />
        </div>
        <div class="popup-footer">
          <div class="btn-group">
            <van-button round @click="onRedeployClose">取消</van-button>
            <van-button round type="info" @click="onRedeploySubmit"
              >确定</van-button
            >
          </div>
        </div>
      </div>
    </van-popup>
    <!--  工作进度  -->
    <van-popup
      v-model="progressVisible"
      position="bottom"
      :style="{ height: '80vh' }"
    >
      <div class="popup-container">
        <div class="popup-header">
          <h3 class="popup-title">工作进度</h3>
          <i
            class="iconclose iconfont iconfont-danchuangguanbi"
            @click="progressVisible = false"
          ></i>
        </div>
        <van-steps direction="vertical" :active="110">
          <van-step v-for="record in flowRecords" :key="record.id">
            <h3>{{ record.flowName }}</h3>
            <p v-if="record.remark">{{ record.remark }}</p>
            <template v-else>
              <p v-for="(item, index) in JSON.parse(record.value)" :key="index">
                <template v-if="item.type === 'group'">
                  {{ item.label }}<br />
                  <span
                    v-for="(groupItem, groupIdx) in JSON.parse(item.value).flat(
                      5
                    )"
                    :key="groupIdx"
                  >
                    {{ groupItem.label }} :
                    {{ groupItem.value }}
                  </span>
                </template>
                <template v-else-if="item.type === 'ckeditor'">
                  {{ item.label }} :
                  <span v-html="item.value"></span>
                </template>
                <template v-else-if="item.type === 'jsa'">
                  {{ item.label }} :
                  <span>{{ getLabel(jsas, item.value.split(",")) }}</span>
                </template>
                <template v-else-if="item.type === 'material'">
                  {{ item.label }} :
                  <span>{{ getLabel(materials, item.value.split(",")) }}</span>
                </template>
                <template v-else-if="item.type === 'product'">
                  {{ item.label }} :
                  <span>{{ getLabel(products, item.value.split(",")) }}</span>
                </template>
                <template v-else-if="item.type === 'intermediate'">
                  {{ item.label }} :
                  <span>{{
                    getLabel(intermediates, item.value.split(","))
                  }}</span>
                </template>
                <template v-else-if="item.type === 'feedstock'">
                  {{ item.label }} :
                  <span>{{ getLabel(feedstocks, item.value.split(",")) }}</span>
                </template>
                <template v-else-if="item.type === 'user'">
                  {{ item.label }} :
                  <span>{{ getLabel(users, item.value.split(",")) }}</span>
                </template>
                <template v-else-if="item.type === 'position'">
                  {{ item.label }} :
                  <span>{{ getLabel(positions, item.value.split(",")) }}</span>
                </template>
                <template v-else-if="item.type === 'indicator'">
                  {{ item.label }} :
                  <span>{{ getLabel(indicators, item.value.split(",")) }}</span>
                </template>
                <template v-else-if="item.type === 'department'">
                  {{ item.label }} :
                  <span>{{ getLabel(department, item.value.split(",")) }}</span>
                </template>
                <template v-else-if="item.type === 'facility'">
                  {{ item.label }} :
                  <span v-if="Array.isArray(JSON.parse(item.value))">{{
                    JSON.parse(item.value)
                      .map(v => v.label)
                      .join(",")
                  }}</span>
                  <span v-else>{{ JSON.parse(item.value).label }}</span>
                </template>

                <template
                  v-else-if="['line', 'title'].includes(item.type)"
                ></template>
                <template
                  v-else-if="['imageUpload', 'docUpload'].includes(item.type)"
                >
                  <span>{{ item.label }} : </span>
                  <span
                    v-for="(doc, docIdx) in JSON.parse(item.value)"
                    :key="docIdx"
                  >
                    {{ doc.name }}
                  </span>
                </template>
                <template v-else-if="item.type === 'cascade'">
                  {{ item.label }} : {{ getCascadeOptions(item, record) }}
                </template>
                <template v-else>
                  {{ item.label }} :
                  {{ item.value }}
                </template>
              </p>
            </template>
            <div class="info">
              <span
                ><i class="iconfont iconfont-yonghu1"></i
                >{{ record.userName }}</span
              >
              <span
                ><i class="iconfont iconfont-riqi"></i
                >{{ record.createTime }}</span
              >
            </div>
          </van-step>
        </van-steps>
      </div>
    </van-popup>
    <fixed-line />
  </div>
</template>

<script>
import {
  getDefinitionFlowForms,
  getPageChangesDetail,
  getValues,
  postChangeTransfers,
  saveValues
} from "@/views/apply/api";
import RenderForm from "@/components/designForm/RenderForm";
import designChangeMixin from "@/components/designForm/mixins/designChangeMixin";
import SelectPerson from "@/components/SelectPerson";
import FixedLine from "./components/FixedLine";

export default {
  name: "ApplyDetail",
  mixins: [designChangeMixin],
  components: {
    RenderForm,
    SelectPerson,
    FixedLine
  },
  props: ["id"],
  data() {
    return {
      form: {},
      renderList: [], // 布局
      renderFormData: {},
      progressVisible: false,
      flowRecords: [],
      selectPersonVisible: false,
      chooseValue: [],
      excludePersonIds: [],
      redeployVisible: false, // 转派popup
      redeployUser: "",
      redeployUserId: "",
      approveVisible: false, //核准popup
      approveLoading: false, // 核准按钮loading
      approveDisabled: false, // 核准按钮是否禁用
      approveRenderFormData: {}, // 核准动态表单
      approveRenderList: [],
      approveLoaded: true
    };
  },
  computed: {
    task() {
      return (
        (this.form.tasks &&
          this.form.tasks.find(v => v.userIds.includes(this.userInfo.id))) ||
        {}
      );
    },
    tasks() {
      return this.form.tasks;
    },
    currentTaskName() {
      return this.tasks && this.tasks.length && this.tasks[0].name;
    },
    showTransfer() {
      let ids = this.tasks?.map(v => v.userIds[0]) || [];
      return (
        ids.includes(this.userInfo.id) &&
        (this.task?.name !== "申请" || !this.flowRecords?.length)
      );
    },
    showWorkflowButton() {
      return !!this.task.id && this.task?.name !== "申请";
    },
    showDesignate() {
      return (
        this.tasks &&
        this.tasks?.length === 1 &&
        this.tasks[0].userIds.includes("system") &&
        this.form.creator === this.userInfo.id
      );
    },
    workflowButtonText() {
      return (
        (this.task && this.task.attributes && this.task.attributes.button) || ""
      );
    },
    flowId() {
      return this.task.flowId;
    },
    flowName() {
      return this.task.name;
    },
    changeId() {
      return this.$route.params.id;
    },
    taskId() {
      return this.task.id;
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { id } = this;
      if (id) {
        const res = await getPageChangesDetail({ id });
        this.form = res;
        this.renderList = JSON.parse(res.settings) || [];
        let records = await getValues(id).catch(() => {});
        if (records?.length) {
          records[0].remark = "提交工作票";
        }
        this.flowRecords = records;
        const layoutValues = this.formatRecordsValue(
          records,
          this.layoutValues
        );
        this.renderFormData = this.initRenderFormData(
          this.renderList,
          layoutValues
        );
      }
    },
    async showWorkflowForm() {
      this.approveVisible = true;
      this.approveDisabled = false;
      await this.$nextTick();
      this.$refs.approveRenderForm.renderForm = {};
      let records = await getValues(this.id).catch(() => {});
      let values = this.formatRecordsValue(records);
      let params = {
        id: this.flowId,
        instanceId: this.form.instanceId
      };
      let renderFormRes = await getDefinitionFlowForms(params).catch(_ => {});

      if (!renderFormRes) {
        this.$toast.clear();
        this.$toast("请检查工作流配置！");
        this.approveDisabled = true;
        return false;
      }
      this.approveLoaded = false;
      this.approveRenderList = JSON.parse(renderFormRes.settings) || [];
      this.approveRenderFormData = this.initRenderFormData(
        this.approveRenderList
      );
      this.$nextTick(() => {
        this.approveRenderFormData = this.initRenderFormData(
          this.approveRenderList,
          values
        );
        this.approveLoaded = true;
      });
    },
    onApproveClose() {
      this.approveVisible = false;
    },
    formatRenderFormData(arr) {
      let fn = function(array) {
        return array.map(v => {
          v.value = "";
          if (v.children && v.children.length) {
            v.value = fn(v.children);
          }
          return {
            label: v.label,
            type: v.type,
            name: v.code,
            value: v.value
          };
        });
      };
      return fn(arr);
    },
    getCascadeOptions(item, row) {
      let settings = JSON.parse(row.settings);
      let obj = settings.find(v => v.code === item.name);
      let treeData = (obj && obj.options.tree) || [];
      let ids = item.value.split(",").map(v => Number(v));
      let arr1 = [];
      let fn = function(arr, ids) {
        arr.map(v => {
          if (ids.includes(v.id)) {
            arr1.push({ id: v.id, label: v.label });
          }
          if (v.children && v.children.length) {
            fn(v.children, ids);
          }
        });
      };
      fn(treeData, ids);
      return arr1.map(v => v.label).join(",");
    },
    async onApproveSubmit() {
      await this.$refs.approveRenderForm.$refs.vanForm.validate();
      const formData = this.$refs.approveRenderForm.renderForm;
      let { changeId, flowName, flowId, taskId } = this;
      let arr = this.formatRenderFormData(this.approveRenderList);
      arr.map(v => {
        Object.keys(formData).map(k => {
          if (k === v.name) {
            if (v.type === "group") {
              let arr1 = [];
              formData[k].map(l => {
                let dataArr = [];
                v.value.map(j => {
                  Object.keys(l).map(s => {
                    let obj = {};
                    if (s === j.name) {
                      obj.value = Array.isArray(l[s]) ? l[s].join(",") : l[s];
                      obj.label = j.label;
                      obj.name = j.name;
                      obj.type = j.type;
                      dataArr.push(obj);
                    }
                  });
                });
                arr1.push(dataArr);
              });
              v.value = JSON.stringify(arr1);
            } else if (
              ["docUpload", "imageUpload", "facility"].includes(v.type)
            ) {
              v.value = JSON.stringify(formData[k]);
            } else {
              v.value = Array.isArray(formData[k])
                ? formData[k].join(",")
                : formData[k];
            }
          }
        });
      });
      let cCData = [];
      let data = {
        changeId,
        flowName,
        flowId,
        taskId,
        value: arr,
        settings: JSON.stringify(this.approveRenderList),
        ccs: cCData
      };
      this.approveLoading = true;
      saveValues(data)
        .then(res => {
          this.approveVisible = false;
          this.init();
        })
        .catch(_ => {})
        .finally(() => (this.approveLoading = false));
    },
    onRedeploySubmit() {
      if (!this.redeployUserId) {
        this.$toast.fail("请选择转派对象");
        return;
      }
      let data = {
        id: this.id,
        taskId: this.tasks[0].id,
        flowId: this.tasks[0].flowId,
        userId: this.redeployUserId
      };
      postChangeTransfers(data).then(res => {
        this.$toast("转派成功");
        this.redeployVisible = false;
        this.redeployUserId = "";
        this.redeployUser = "";
        this.init();
      });
    },
    onShowProgress() {
      this.progressVisible = true;
    },
    onRedeployClose() {
      this.redeployVisible = false;
      this.redeployUserId = "";
      this.redeployUser = "";
    },
    onPersonConfirm(id, list) {
      if (list?.length) {
        const userInfo = list[0];
        this.redeployUserId = userInfo.id;
        this.redeployUser = userInfo.name;
      } else {
        this.redeployUserId = "";
        this.redeployUser = "";
      }
      this.chooseValue = (list || []).map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },
    getLabel(arr1, arr2) {
      return (
        arr1 &&
        arr1
          .filter(s => arr2.includes(s.value))
          .map(s => s.label)
          .join(",")
      );
    }
  }
};
</script>

<style lang="scss" scoped>
$main-color1: #2e2e4d;
.apply-detail {
  background: #fff;
  .base-info {
    position: relative;
    background: #fff;
    padding: 34px 16px 12px;
    border-bottom: 8px solid #eceef2;
    .ban {
      width: 128px;
      height: 24px;
      position: absolute;
      top: -1px;
      left: 0;
      color: #fff;
      font-size: 12px;
      text-indent: -1em;
      text-align: center;
      line-height: 24px;
      background: url("./images/ban.png") no-repeat center;
      background-size: 100% 100%;
    }
    .flow-state {
      position: absolute;
      top: 34px;
      right: 16px;
      padding: 2px 4px;
      font-size: 11px;
      color: #5a96e0;
      border: 1px solid #5a96e0;
      background: #5a96e010;
    }
    h3 {
      font-size: 16px;
      color: $main-color1;
      font-weight: 500;
      margin-bottom: 4px;
    }
    p {
      margin: 8px 0;
      font-size: 14px;
      color: #8c8f97;
      span {
        color: #3b4664;
      }
    }
  }
  .main-title {
    margin: 16px 0 0 16px;
    font-family: PingFangSC-Medium, PingFang SC, serif;
    font-size: 16px;
    font-weight: 500;
    color: $main-color1;
  }
  .empty-box {
    height: 88px;
  }
  .button-operate {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 88px;
    background: #fff;
    .btn-group {
      margin: 10px 16px;
      display: flex;
      button {
        flex: 1;
        &:nth-child(1) {
          margin-right: 14px;
        }
      }
    }
  }
  .popup-container {
    .popup-header {
      position: relative;
      margin-top: 16px;
      .popup-title {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #2e2e4d;
      }
      > i {
        position: absolute;
        top: 0;
        right: 16px;
      }
    }
    .van-steps {
      ::v-deep {
        .van-step {
          &--finish {
            .van-step__circle,
            .van-step__line {
              background-color: #c3c6d0;
            }
            .van-step__circle {
              width: 10px;
              height: 10px;
            }
          }
          font-size: 12px;
          color: #8c8f97;
          h3 {
            font-size: 14px;
            color: #2e2e4d;
            font-weight: 500;
            margin: 4px 0;
          }
          p {
            margin: 6px 0;
          }
          .info {
            > span:nth-child(1) {
              margin-right: 16px;
            }
            i {
              margin-right: 4px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
  .redeploy-popup-container {
    position: relative;
    height: calc(100% - 16px);
    .popup-body {
      margin-left: 16px;
      margin-right: 16px;
      .tip {
        margin-top: 24px;
        color: #8c8f97;
        font-size: 14px;
      }
      ::v-deep {
        .van-cell {
          transform: translateX(-10px);
        }
      }
    }
    .popup-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 88px;
      background: #ffffff;
      box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.1);
      .btn-group {
        margin: 10px 16px;
        display: flex;
        button {
          flex: 1;
          &:nth-child(1) {
            margin-right: 14px;
          }
        }
      }
    }
  }
}
</style>
